<template>
    <div @click.stop>
        <v-btn
            v-if="showButton"
            @click.stop="togglePanel"
            :block="block"
            :class="buttonClass"
            :height="inline ? 'null' : height"
            :icon="hasIcon && label == null"
            :large="large"
            :small="small"
            :title="title">
            <v-icon v-if="hasIcon && label == null" :large="large" :small="small">{{ leftIcon || rightIcon }}</v-icon>
            <v-row v-else-if="!inline" class="mt-0 mb-3">
                <v-col v-if="leftIcon != null" class="mx-0 mx-auto px-auto" cols="12">
                    <v-icon :large="large" :small="small">{{ leftIcon || rightIcon }}</v-icon>
                </v-col>
                <v-col v-if="label != null" class="ma-0 pa-0" cols="12">
                    <div>{{ label }}</div>
                </v-col>
            </v-row>
            <span v-else>
                <v-icon v-if="leftIcon != null" :large="large" :small="small" :left="label != null">{{ leftIcon }}</v-icon>
                {{ label }}
                <v-icon v-if="rightIcon != null" :large="large" :small="small" :right="label != null">{{ rightIcon }}</v-icon>
            </span>
        </v-btn>

        <v-navigation-drawer
            v-model="mShow"
            app
            :bottom="bottom"
            :clipped="clipped"
            :hide-overlay="hideOverlay"
            right
            style="height: 100%;"
            :width="navWidth">
            <v-toolbar dense>
                <v-btn
                    icon
                    small
                    title="Close"
                    @click.stop="closePanel">
                    <v-icon>{{ ($vuetify.breakpoint.mobile && bottom) ? 'mdi-arrow-down' : 'mdi-arrow-right' }}</v-icon>
                </v-btn>
                <v-toolbar-title>{{ label }}</v-toolbar-title>
                <v-spacer />
                <slot
                    name="toolbar-right"
                    v-bind:items="filteredItems"
                    v-bind:allItems="asyncItems">
                </slot>
                <v-btn
                    v-if="canRefresh"
                    icon
                    small
                    title="Refresh"
                    @click.stop="refresh">
                    <v-icon small>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>
            <v-toolbar v-if="!hideSearch && (showFilters || canSearch || canSearchLocal || showSearch)" dense>
                <v-btn
                    v-if="canSearchLocal || canSearch"
                    :small="small"
                    icon 
                    @click.stop="showSearch = !showSearch">
                    <v-icon v-if="!showSearch" small>mdi-magnify</v-icon>
                    <v-icon v-else small>mdi-close</v-icon>
                </v-btn>

                <v-slide-x-transition>
                    <v-text-field
                        v-if="(canSearch || canSearchLocal) && showSearch"
                        flat
                        dense
                        hide-details
                        hide-no-data
                        solo-inverted
                        small
                        single-line
                        prepend-inner-icon="mdi-close"
                        append-icon="mdi-magnify"
                        label="Find"
                        v-model="searchInput"
                        @click:prepend-inner.stop="searchInput = null"
                        @click:append.stop="refresh" />
                </v-slide-x-transition>
                
                <v-spacer />

                <v-chip-group
                    v-if="showFilters"
                    v-model="selectedFilters"
                    multiple
                    small>
                    <v-fade-transition hide-on-leave>
                        <v-btn v-if="isFilterChanged"
                            text
                            small
                            @click.stop="refresh">
                            <v-icon small left>mdi-filter</v-icon>Apply
                        </v-btn>
                    </v-fade-transition>
                    <v-chip
                        v-for="(filter, index) in chipFilters"
                        filter
                        outlined
                        small
                        :key="index">
                        {{ filter }}
                    </v-chip>
                </v-chip-group>
            </v-toolbar>
            <v-alert v-model="errorMsg" dismissible type="error">{{ errorMsg }}</v-alert>

            <v-slide-y-transition>
                <v-btn
                    v-if="isChanged"
                    block
                    class="primary"
                    @click.stop="save">
                    Save and Close <v-icon right>mdi-content-save</v-icon>
                </v-btn>
            </v-slide-y-transition>

            <slot name="top"
                v-bind:items="filteredItems"
                v-bind:allItems="asyncItems">
            </slot>
            <slot name="content"
                v-bind:items="filteredItems"
                v-bind:allItems="asyncItems">
                <v-list 
                    v-if="isLengthyArray(filteredItems)"
                    dense
                    height="auto"
                    class="overflow-y-auto">
                    <template v-for="(item, index) in filteredItems">
                        <slot 
                            name="listItem" 
                            v-bind:item="item" 
                            v-bind:index="index" 
                            v-bind:onChange="updateChange"
                            v-bind:isEditing="canEdit"
                            v-bind:items="filteredItems"
                            v-bind:allItems="asyncItems">
                            <v-list-item
                                :key="index"
                                dense>
                                <slot 
                                    v-bind:item="item"
                                    v-bind:index="index" 
                                    v-bind:onChange="updateChange"
                                    v-bind:isEditing="canEdit"
                                    v-bind:items="filteredItems"
                                    v-bind:allItems="asyncItems">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ itemText ? getNestedValue(item, itemText) : item }}</v-list-item-title>
                                    </v-list-item-content>
                                </slot>
                            </v-list-item>
                        </slot>
                        <v-divider v-if="dividers" :key="'d' + index" />
                    </template>
                    <slot name="bottom" />
                </v-list>
            </slot>

            <v-overlay :value="loadingMsg != null" absolute key="6" class="text-center">
                <v-progress-circular indeterminate size="32" />
                <p>{{ loadingMsg }}</p>
            </v-overlay>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { copyDeep } from '~helpers';

export default {
    name: 'BT-Sidebar-Items',
    data: function() {
        return {
            asyncItems: [],
            errorMsg: null,
            isAllLoaded: false,
            isEditing: false,
            isFilterChanged: false,
            isLoaded: false,
            itemJSON: null,
            links: [],
            loadingMsg: null,
            mParams: { includeDetails: false },
            mProxyID: null,
            mShow: false,
            originalFilters: [],
            searchString: null,
            searchInput: null,
            selectedFilters: [],
            showSearch: false,
            snapshotItemJSON: null,
        }
    },
    props: {
        adjustParams: {
            type: Function,
            default: null
        },
        block: {
            type: Boolean,
            default: false
        },
        bottom: {
            type: Boolean,
            default: true
        },
        buttonClass: {
            type: String,
            default: 'ma-1'
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        canSearchLocal: {
            type: Boolean,
            default: true
        },
        canSearch: {
            type: Boolean,
            default: true
        },
        clipped: {
            type: Boolean,
            default: true
        },
        closeToggle: {
            type: Boolean,
            default: false
        },
        customFilters: { //a list of custom filters which when selected adjust the query parameter
            type: Array,
            default: () => [] //{ return [{ filter: 'Empties', onFilter: (list, propName) => { return list.filter(x => nestedValue(x, propName) == null) } }] } //{ filter: String, onFilter: list => { list }, filterFunction: () => { return String }, paramProp: (default = 'query') }
        },
        customURL: {
            type: String,
            default: null
        },
        defaultFilters: { //a list of strings which are names of filters which start as selected
            type: Array,
            default: null
        },
        dividers: {
            type: Boolean,
            default: true
        },
        filterProp: {
            type: String,
            default: null
        },
        hideOverlay: {
            type: Boolean,
            default: true
        },
        hideSearch: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        itemProperties: {
            type: Array,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        leftIcon: {
            type: String,
            default: null
        },
        navigation: {
            type: String,
            default: null
        },
        navWidth: {
            type: String,
            default: '350'
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        openToggle: {
            type: Boolean,
            default: false
        },
        params: {
            type: Object,
            default: null //() => { return { }}
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        resetToggle: { //different to refresh toggle because pagination and default filters are all reset
            type: Boolean,
            default: false
        },
        rightIcon: {
            type: String,
            default: null
        },
        searchProps: {
            type: Array,
            default: null
        },
        show: {
            type: Boolean,
            default: false
        },
        showButton: {
            type: Boolean,
            default: true
        },
        showFilters: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        trackChanges: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        asyncItems: {
            deep: true,
            handler() {
                this.updateChange();
            }
        },
        closeToggle() {
            if (this.mShow) {
                this.mShow = false;
                this.$emit('update:show', this.mShow);
            }
        },
        openToggle() {
            console.log('opening toggle');
            if (!this.mShow){
                this.mShow = true;
                this.$emit('update:show', this.mShow);
            }
        },
        params: function(val) {
            if (JSON.stringify(val) !== JSON.stringify(this.mParams)) {
                this.mParams = copyDeep(val);
            }
        },
        refreshToggle: function() {
            this.refresh();
        },
        resetToggle: function() {
            this.reset();
        },
        mShow: function(val) {
            if (val && !this.isLoaded) {
                this.pullItems();
            }
        },
        show(val) {
            this.mShow = val;
        }
    },
    created() {
        if (this.params != null) {
            this.mParams = this.params;
        }
    },
    mounted() {
        if (this.params != null) {
            this.mParams = this.params;
        }

        if (this.isLengthyArray(this.defaultFilters)) {
            this.defaultFilters.forEach(dFilter => {
                this.selectedFilters.push(this.chipFilters.findIndex(y => y == dFilter));
            })
            this.originalFilters = this.selectedFilters;
        }
        
        this.mShow = this.show;
        
    },
    computed: {
        canEdit() {
            return this.$canEdit(this.navigation);
        },
        chipFilters() {
            var filterList = [];
            
            if (this.isLengthyArray(this.customFilters)) {
                filterList.push(...this.customFilters.map(x => x.filter));
            }

            return filterList;
        },
        filteredItems() {
            var l = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
            
            if (this.canSearchLocal && this.searchInput != null) {
                l = l.filter(x => this.hasSearch(x, this.searchInput, this.searchableProperties));
            }

            if (this.isLengthyArray(this.selectedFilters)) {
                var customFilters = [];

                this.selectedFilters.forEach(selectedIndex => {
                    var sFilter = this.chipFilters[selectedIndex];
                    if (this.isLengthyArray(this.customFilters) && this.customFilters.some(cF => cF.filter == sFilter)) {
                        customFilters.push(this.customFilters.find(cF => cF.filter == sFilter));
                    }
                });

                var cFilters = this.customFilters.filter(x => x.onFilter != null);
                cFilters.forEach(f => {
                    l = f.onFilter(l);
                });

            }

            return l;
        },
        hasIcon() {
            return this.leftIcon != null || this.rightIcon != null;
        },
        height() {
            return this.small ? 30 : (this.large ? 80 : 'auto');
        },
        isChanged() {
            return this.itemJSON != this.snapshotItemJSON;
        },
        proxyCompanyID() {
            if (this.proxyID != null) {
                return this.proxyID;
            }
            else if (this.mProxyID != null) {
                return this.mProxyID;
            }
            else if (this.proxyIDParam != null && this.$route.query != null && this.$route.query[this.proxyIDParam] != null) {
                return this.$route.query[this.proxyIDParam];
            }

            return null;
        },
        searchableProperties() {
            return this.searchProps;
        },
        showEditButton() {
            return false; //this.canEdit && this.$BlitzIt.auth.canEdit(this.navigation);
        },
    },
    methods: {
        closePanel() {
            this.$emit('close');
            this.mShow = false;
            this.$emit('update:show', this.mShow);
        },
        createSnapshot(data) {
            var copy = copyDeep(data);
            return JSON.stringify(copy);
        },
        getParamObj() {
            var paramObj = copyDeep(this.mParams);

            if (this.searchString && this.searchString.length > 0) {
                if (this.canSearch) {
                    paramObj.searchString = this.searchString;
                }
            }
            
            if (this.isLengthyArray(this.selectedFilters)) {
                //var serverFilters = [];
                var customFilters = [];
                this.selectedFilters.forEach(selectedIndex => {
                    var sFilter = this.chipFilters[selectedIndex];
                    if (this.isLengthyArray(this.customFilters) && this.customFilters.some(cF => cF.filter == sFilter)) {
                        customFilters.push(this.customFilters.find(cF => cF.filter == sFilter));
                    }
                });

                if (this.isLengthyArray(customFilters)) {
                    var query = '';
                    customFilters.forEach(cFilter => {
                        if (cFilter.paramProp == null || cFilter.paramProp == 'query') {
                            query = query + (query.length > 0 ? 'ANDALSO' : '') + (cFilter.filterFunction());
                        }
                        else {
                            paramObj[cFilter.paramProp] = cFilter.filterFunction();
                        }
                    })

                    if (query.length > 0) {
                        paramObj.query = query;
                    }
                }
            }
            
            if (this.isLengthyArray(this.itemProperties)) {
                paramObj.properties = copyDeep(this.itemProperties).toString();
            }

            return paramObj;
        },
        async pullItems(refresh = false) {
            if (this.navigation == null || this.mParams == null) {
                return;
            }

            try {
                this.loadingMsg = 'Pulling Data';
                this.$forceUpdate();
                
                var paramObj = this.getParamObj();

                var res = await this.$BlitzIt.store.getAll(this.navigation, paramObj, refresh, this.proxyCompanyID, this.customURL);
                
                res = res.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));

                if (this.onPullSuccessAsync != null) {
                    res = await this.onPullSuccessAsync(res, refresh); //, this.bladeData);
                }
                // else {
                //     this.asyncItems = res;
                // }

                this.asyncItems = res.map(x => Object.assign(x, { original: this.createSnapshot(x) }));
                
                if (this.trackChanges) {
                    this.snapshotItemJSON = this.createSnapshot(this.asyncItems);
                    this.itemJSON = this.snapshotItemJSON;
                }

                this.$emit('fetched', this.asyncItems);
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        refresh(refresh = true) {
            this.searchString = this.searchInput;
            this.showSearch = false;
            this.errorMsg = null;
            this.pullItems(refresh);
        },
        reset() {
            this.searchString = null;
            this.searchInput = null;
            this.$BlitzIt.store.clear(this.manyNavigation);
            this.pullItems(true);
        },
        async save() {
            for (let i = 0; i < this.asyncItems.length; i++) {
                const asyncItem = this.asyncItems[i];

                asyncItem.loadingCount = 0;
                asyncItem.errorMsg = null;

                var copiedItem = copyDeep(asyncItem);
                delete copiedItem.original;

                try {
                    if (asyncItem.original != this.createSnapshot(copiedItem)) {
                        if (copiedItem.id == null) {
                            this.loadingMsg = 'Creating';
                            console.log('creating');
                            console.log(copiedItem);
                            var res = await this.$BlitzIt.store.post(this.navigation, copiedItem, this.proxyCompanyID)
                            copiedItem.id = res.id;
                            copiedItem.rowVersion = res.rowVersion;
                        }
                        else {
                            delete copiedItem.loadingCount;
                            delete copiedItem.errorMsg;
                            
                            this.loadingMsg = 'Updating';
                            console.log('updating');
                            console.log(copiedItem);
                            var aRes = await this.$BlitzIt.store.patch(this.navigation, copiedItem, this.proxyCompanyID);
                            copiedItem.rowVersion = aRes.rowVersion;
                        }

                        asyncItem.id = copiedItem.id;
                        asyncItem.rowVersion = copiedItem.rowVersion;
                    }
                }
                catch (err) {
                    asyncItem.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    this.loadingMsg = null;
                }
            }

            if (this.trackChanges) {
                this.snapshotItemJSON = this.createSnapshot(this.asyncItems);
                this.itemJSON = this.snapshotItemJSON;
            }
        },
        togglePanel() {
            this.mShow = !this.mShow;
            this.$emit('update:show', this.mShow);
        },
        updateChange() {
            if (this.trackChanges) {
                this.itemJSON = this.createSnapshot(this.asyncItems);
            }
        },
    }
}
</script>